export default [{   //星探正在开发
	path: '/star/develop',
	component: () => import('@/views/develop'),
	name: 'develop',
	meta: {
		title: '正在开发'
	},
}, {   //推广正在开发
	path: '/promotion/develop',
	component: () => import('@/views/develop'),
	name: 'develop',
	meta: {
		title: '正在开发'
	},
}]
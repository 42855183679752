

const state = {
	searchDialogVisible: false,    //搜索模态框
	loginVisible: false  //登录模态框
}

const mutations = {
	changeSearchDialogVisible (state, payload) {
		state.searchDialogVisible = payload
	},
	changeLoginVisible (state, payload) {
		state.loginVisible = payload
	}
}

const actions = {

}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
export default [{
	path: '/certification',
	component: () => import('@/views/certification'),
	name: 'certification',
	meta: {
		title: '身份查询'
	},
}, {
	path: '/certification-result',
	component: () => import('@/views/certification/result'),
	name: 'certification-result',
	meta: {
		title: '身份查询结果'
	},
},
{
	path: '/certification-organ',
	component: () => import('@/views/certification/organ'),
	name: 'certification-organ',
	meta: {
		title: '常设机构'
	},
}
]
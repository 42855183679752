import router from '@/router'
const resolve = (path, query) => {
	const { href } = router.resolve({
		path,
		query
	});
	window.open(href, '_blank');//打开新的窗口
}
const push = (path, query) => {
	router.push({
		path, query
	})
}
const state = {
	charts: [],
	rank: [],
	showPraise: false
}

const mutations = {
	showPraiseFn (state) {
		state.showPraise = true
		setTimeout(() => {
			state.showPraise = false
		}, 1000);
	}
}

const actions = {
	toDetail (_, { type, uuid, query }) {
		const parms = query ? { uuid, ...query } : { uuid }
		if (type === 0) {
			resolve("/homepage/imageDetail", parms)
		} else if (type === 1) {
			resolve("/homepage/articleDetail", parms)
		} else if (type === 2) {
			resolve("/homepage/videoDetail", parms)
		} else if (type === 3) {
			resolve("/homepage/transpondDetail", parms)
		} else if (type === 4) {
			resolve("/homepage/shortVideoDetail", parms)
		} else if (type === 5) {
			resolve("/college/collegedetial", parms)
		}
	},
	// 跳转个人中心
	toPersonal (_, { uuid, newPage }) {
		if (newPage) {
			resolve("/homepage/user/personal", { uuid })
		} else {
			push("/homepage/user/personal", { uuid })
		}
	},
	// 跳转粉丝和关注列表
	toFollowe (_, { uuid, type, newPage }) {
		if (newPage) {
			resolve("/homepage/user/followe", { uuid, type })
		} else {
			push("/homepage/user/followe", { uuid, type })
		}
	},
	// 跳转编辑资料
	toProfileEdit (_, { uuid, newPage }) {
		if (newPage) {
			resolve("/homepage/user/profileEdit", { uuid })
		} else {
			push("/homepage/user/profileEdit", { uuid })
		}
	},

}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
// import router from '@/router'
// // const resolve = (path, query) => {
// // 	const { href } = router.resolve({
// // 		path,
// // 		query
// // 	});
// // 	window.open(href, '_blank');//打开新的窗口
// // }
// const push = (path, query) => {
// 	router.push({
// 		path, query
// 	})
// }
// const state = {
// 	charts: [],
// 	rank: []
// }

// const mutations = {
// }

// const actions = {
// 	toDetail (_, { type, uuid, query }) {
// 		const parms = query ? { uuid, ...query } : { uuid }
// 		if (type === 0) {
// 			push("/homepage/imageDetail", parms)
// 		} else if (type === 1) {
// 			push("/homepage/articleDetail", parms)
// 		} else if (type === 2) {
// 			push("/homepage/videoDetail", parms)
// 		} else if (type === 3) {
// 			push("/homepage/transpondDetail", parms)
// 		} else if (type === 4) {
// 			push("/homepage/shortVideoDetail", parms)
// 		}
// 	},
// 	// 跳转个人中心
// 	toPersonal (_, { uuid, newPage }) {
// 		if (newPage) {
// 			push("/homepage/user/personal", { uuid })
// 		} else {
// 			push("/homepage/user/personal", { uuid })
// 		}
// 	},
// 	// 跳转粉丝和关注列表
// 	toFollowe (_, { uuid, type, newPage }) {
// 		if (newPage) {
// 			push("/homepage/user/followe", { uuid, type })
// 		} else {
// 			push("/homepage/user/followe", { uuid, type })
// 		}
// 	},
// 	// 跳转编辑资料
// 	toProfileEdit (_, { uuid, newPage }) {
// 		if (newPage) {
// 			push("/homepage/user/profileEdit", { uuid })
// 		} else {
// 			push("/homepage/user/profileEdit", { uuid })
// 		}
// 	},

// }

// export default {
// 	namespaced: true,
// 	state,
// 	mutations,
// 	actions
// }
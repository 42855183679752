import request from '@/utils/request'
export default {
	//获取会话列表
	messageListApi () {
		return request.post(`/api/message/message-list`)
	},
	//聊天框中的好友聊天记录
	chatDetailApi (params) {
		return request.post(`/api/message/chat-detail`, params)
	},
	//给好友发送私聊消息
	sendMessageApi (params) {
		return request.post(`/api/message/send-message`, params)
	}
}

import common from './home/common'  //公共
import login from './home/login'  //登录相关
import dynamic from './home/dynamic'  //动态相关
import user from './home/user'  //动态相关
import kingReq from './star/index'  //星探
import chat from './home/chat'  //私聊
import college from './college/college'  //格调学院
import message from './message/message'
export default {
	...common,
	...login,
	...dynamic,
	...user,
	...kingReq,
	...chat,
	...college,
	...message
}
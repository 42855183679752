import Vue from 'vue'
import App from './App.vue'
import utils from '@/utils';
import * as filters from '@/utils/filters'
import router from './router'
import store from './store'
import api from "@/api";
import '@/assets/font/YouSheBiaoTiHei-2.css';
import './plugins/element.js'
import './plugins/swiper.js'
import './plugins/animate.js'
import './plugins/viewer.js'
import './plugins/VTooltip.js'
import './plugins/RSA.js'
import empty from '@/components/empty/empty.vue'
try {
	Object.keys(utils).forEach(e => {
		if (e[0] === '$')
			Vue.prototype[e] = utils[e];
	})
} catch (e) {
	console.log('引入公共方法错误：', e)
}
try {
	Object.keys(filters).forEach(key => {
		Vue.filter(key, filters[key])
	})
} catch (e) {
	console.log('引入过滤器错误：', e)
}
const app = new Vue({
	router,
	store,
	render: h => h(App)
})
// Vue.use(chat, app)
Vue.prototype.$api = api
Vue.config.productionTip = false
Vue.prototype.$Bus = new Vue()
Vue.component('empty', empty)

// new Vue({
// 	router,
// 	store,
// 	render: h => h(App),
// }).$mount('#app')

app.$mount('#app')
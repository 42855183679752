import Vue from 'vue'
import Vuex from 'vuex'
import createVuexAlong from 'vuex-along'
import layouts from './modules/layouts'
import user from './modules/user'
import homepage from './modules/homepage'

Vue.use(Vuex)

const store = new Vuex.Store({
	modules: {
		layouts,
		user,
		homepage,
	},
	// vuex 状态储存   文档-https://github.com/boenfu/vuex-along#readme
	plugins: [createVuexAlong({
		local: {
			list: ["user"],
		}
	})]
})

export default store

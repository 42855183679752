import request from '@/utils/request'
export default {
	// 登录
	loginApi (params) {
		return request.post(`/api/auth/phone/login`, params)
	},
	// 获取验证码
	verifyCodeApi (params) {
		return request.post(`/api/phone/verify-code`, params)
	},
	// 获取用户资料
	profileEditApi (params) {
		return request.post(`/api/user/profile-edit`, params)
	},
	// 获取上直播id
	shangzhiboApi () {
		return request.post(`/api/shangzhibo/cookie-id`)
	},
	// 生成确认二维码
	confirmCodeApi (params) {
		return request.post(`api/confirm-code/generate`, params)
	},
	// 查询扫码结果
	confirmCodeCheckApi (params) {
		return request.post(`api/confirm-code/check`, params)
	}
}
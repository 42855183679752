export default [{
	path: '/college',
	component: () => import('@/layouts/collegelayouts'),
	redirect: "/college/collegehome",
	children: [
		{
			path: '/college/collegehome',
			name: 'collegehome',
			component: () => import('@/views/college/collegehome'),
			meta: {
				title: '格调学院'
			}
		},
		{
			path: '/college/collegedetial',
			name: 'collegedetial',
			component: () => import('@/views/college/collegedetial'),
			meta: {
				title: '格调学院详情页'
			}
		},
		{
			path: "/college/confirmOrder",
			name: 'confirmOrder',
			component: () => import('@/views/college/confirmOrder/index.vue'),
			meta: {
				title: '确认订单'
			}
		}
	]
}
]